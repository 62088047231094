<template>
  <v-app>
    <v-main>
      <v-container fluid>
        <v-layout align-center justify-center>
          <v-flex xs12 sm10 md8>
            <h1
              style="
                text-align: left;
                float: left;
                padding-right: 2em;
                font-size: 2.5em;
                filter: drop-shadow(2px 4px 8px #585858);
              "
            >
              <span style="color: black">Apúntate a nuestros talleres</span
              ><br /><span style="color: white"
                >¿CUÁL ES TU CENTRO<br />MÁS CERCANO?
                <v-select
                  v-model="idCentro"
                  :items="centros"
                  item-text="nombre"
                  item-value="id"
                  label="Selecciona tu centro"
                  @change="loadWorkshopFilter"
                ></v-select>
              </span>
              <span style="font-size: 1rem">
                <a
                  href="https://www.lahabitacionsaludable.com/servicio-de-nutricion-pediatrica/"
                  target="blank"
                  >Más información sobre los talleres AQUÍ</a
                >
              </span>
            </h1>
            <v-img
              class="shrink mr-2"
              contain
              src="@/assets/flecha.png"
              transition="scale-transition"
              width="150"
              style="
                filter: drop-shadow(2px 4px 8px #585858);
                -moz-transform: rotate(-25deg);
                -o-transform: rotate(-25deg);
                -webkit-transform: rotate(-25deg);
                transform: rotate(-25deg);
              "
            />
            <div style="clear: both"></div>
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title
                  >Déjanos tus datos
                  <span v-if="invita">y los de tu acompañante</span>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-layout wrap>
                    <v-flex xs12 md12 sm12>
                      <v-autocomplete
                        v-model="selectWorkshops"
                        :items="workshops"
                        item-value="id"
                        :item-text="(item) => `${item.fecha} | ${item.nombre}`"
                        flat
                        hide-no-data
                        label="Buscar taller"
                        prepend-inner-icon="mdi-account-search"
                        solo-inverted
                        @change="clear"
                      >
                        <template v-slot:item="{ item }">
                          <div>
                            <strong
                              >{{ item.fecha }} | {{ item.nombre }}</strong
                            >
                            <br />
                            Precio: {{ item.precio }}€
                          </div>
                        </template>
                      </v-autocomplete>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex xs12 md3 sm12>
                      <v-text-field
                        label="Nombre"
                        v-model="post.nombre"
                        :rules="rules.nombre"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md3 sm12>
                      <v-text-field
                        label="Apellidos"
                        v-model="post.apellidos"
                        :rules="rules.apellidos"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md3 sm12>
                      <v-text-field
                        label="Email"
                        v-model="post.email"
                        :rules="rules.email"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md3 sm12>
                      <v-text-field
                        autocomplete="off"
                        v-model="post.telefono"
                        type="tel"
                        label="Teléfono"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex xs12 md3 sm12>
                      <v-menu
                        v-model="menuDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            autocomplete="off"
                            v-model="dateFormatted"
                            label="Fecha de nacimiento"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          @change="changeDateFormatted('date')"
                          locale="es"
                          :first-day-of-week="1"
                          v-model="post.fnac"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs12 md4 sm12>
                      <v-menu
                        v-model="menuDateBebe"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            autocomplete="off"
                            v-model="dateFormattedBebe"
                            label="Fecha de nacimiento de mi bebé"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          @change="changeDateFormatted('dateBebe')"
                          locale="es"
                          :first-day-of-week="1"
                          v-model="post.fnacBebe"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <h2 v-if="plazas && plazas < 4 && plazas >= 0">
                      {{ plazas }} plazas disponibles
                    </h2>
                  </v-layout>
                  <v-layout wrap v-if="invita > 0">
                    <h3>Invitado 1</h3>
                  </v-layout>
                  <v-layout wrap v-if="invita > 0">
                    <v-flex xs12 md2 sm12>
                      <v-text-field
                        label="Nombre"
                        v-model="invita1.nombre"
                        :rules="rules.nombre"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md3 sm12>
                      <v-text-field
                        label="Apellidos"
                        v-model="invita1.apellidos"
                        :rules="rules.apellidos"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md3 sm12>
                      <v-text-field
                        label="Email"
                        v-model="invita1.email"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md2 sm12>
                      <v-text-field
                        autocomplete="off"
                        v-model="invita1.telefono"
                        type="tel"
                        label="Teléfono"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md2 sm12>
                      <v-menu
                        v-model="menuDate1"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            autocomplete="off"
                            v-model="dateFormatted1"
                            label="Fecha de nacimiento"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          @change="changeDateFormatted('date1')"
                          locale="es"
                          :first-day-of-week="1"
                          v-model="invita1.fnac"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap v-if="invita > 1">
                    <h3>Invitado 2</h3>
                  </v-layout>
                  <v-layout wrap v-if="invita > 1">
                    <v-flex xs12 md2 sm12>
                      <v-text-field
                        label="Nombre"
                        v-model="invita2.nombre"
                        :rules="rules.nombre"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md3 sm12>
                      <v-text-field
                        label="Apellidos"
                        v-model="invita2.apellidos"
                        :rules="rules.apellidos"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md3 sm12>
                      <v-text-field
                        label="Email"
                        v-model="invita2.email"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md2 sm12>
                      <v-text-field
                        autocomplete="off"
                        v-model="invita2.telefono"
                        type="tel"
                        label="Teléfono"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md2 sm12>
                      <v-menu
                        v-model="menuDate2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            autocomplete="off"
                            v-model="dateFormatted2"
                            label="Fecha de nacimiento"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          @change="changeDateFormatted('date2')"
                          locale="es"
                          :first-day-of-week="1"
                          v-model="invita2.fnac"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap v-if="invita > 2">
                    <h3>Invitado 3</h3>
                  </v-layout>
                  <v-layout wrap v-if="invita > 2">
                    <v-flex xs12 md2 sm12>
                      <v-text-field
                        label="Nombre"
                        v-model="invita3.nombre"
                        :rules="rules.nombre"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md3 sm12>
                      <v-text-field
                        label="Apellidos"
                        v-model="invita3.apellidos"
                        :rules="rules.apellidos"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md3 sm12>
                      <v-text-field
                        label="Email"
                        v-model="invita3.email"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md2 sm12>
                      <v-text-field
                        autocomplete="off"
                        v-model="invita3.telefono"
                        type="tel"
                        label="Teléfono"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md2 sm12>
                      <v-menu
                        v-model="menuDate3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            autocomplete="off"
                            v-model="dateFormatted3"
                            label="Fecha de nacimiento"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          @change="changeDateFormatted('date3')"
                          locale="es"
                          :first-day-of-week="1"
                          v-model="invita3.fnac"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex xs12 md6 sm12>
                      <v-radio-group v-model="invitados" row @change="invitar">
                        <v-radio label="Quiero llevar invitado" value="yes"></v-radio>
                        <v-radio label="No quiero llevar invitado" value="no"></v-radio>
                      </v-radio-group>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex xs12 md6 sm12>
                      <v-checkbox
                        v-model="post.lopd"
                        label="¿Quieres recibir nuestra newsletter?"
                      ></v-checkbox>
                    </v-flex>
                    <v-flex xs12 md6 sm12 v-if="invita">
                      <v-checkbox
                        v-model="autorizado"
                        :disabled="true"
                        label="Todos los participantes me han autorizado a dar sus datos"
                      ></v-checkbox>
                    </v-flex>
                  </v-layout>
                </v-form>
                <h3>{{ message }}</h3>
                <div v-if="formHtml != ''">
                  <iframe
                    id="redsys-form"
                    style="
                      width: 100%;
                      height: 900px;
                      border: none;
                      display: none;
                    "
                    :srcdoc="formHtml"
                  ></iframe>
                </div>
              </v-card-text>
              <v-card-actions v-if="formHtml == ''">
                <v-spacer></v-spacer>
                <v-btn @click="invitar" text color="tertiary"
                  >Añadir a invitado</v-btn
                >
                <v-btn @click="buy" color="tertiary">Comprar</v-btn>
              </v-card-actions>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <b>¿QUÉ PASARÁ CON LOS DATOS?</b>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    Para tu tranquilidad, en la La Habitación Saludable nos
                    tomamos tu confianza muy en serio y por eso cumplimos con
                    Reglamento General de Protección de Datos 2016/679 (RGPD).
                    Te informamos que los datos solicitados y facilitados serán
                    incorporados a un fichero bajo la responsabilidad de AZAHAR
                    SALUD S.L., empresa propietaria de la marca comercial LA
                    HABITACIÓN SALUDABLE, con C.I.F. B91734376. Tus datos serán
                    gestionados con la finalidad de informar y comunicar sobre
                    todo aquello relativo a la prestación de nuestros servicios
                    profesionales y actividades relacionadas con Azahar Salud
                    S.L.<br /><br />
                    Al hacer clic en "COMPRAR", aceptas que podamos procesar tus
                    datos tal y como te hemos contado. Si nos hacemos muy
                    “pesados”, cambias de opinión, quieres que cambiemos algún
                    dato, o simplemente ya no te interesa lo que te contamos,
                    puedes pedirnos que borremos tus datos de nuestro fichero o
                    los modifiquemos, mandándonos un email a
                    comunicacion@lahabitacionsaludable.com (o simplemente date
                    de baja haciendo clic en el enlace "cancela tu suscripción"
                    que hay en el pie de página de cualquier correo electrónico
                    nuestro que recibas).<br />
                    <a
                      target="blank"
                      href="https://www.lahabitacionsaludable.com/aviso-legal/"
                      >Aviso legal</a
                    > 
                    | 
                    <a
                      target="blank"
                      href="https://www.lahabitacionsaludable.com/privacidad-cookies/"
                      >Política de privacidad y cookies</a
                    > 
                    | 
                    <a
                      target="blank"
                      href="https://www.lahabitacionsaludable.com/politica-de-devoluciones/"
                      >Condiciones generales y política de devoluciones</a
                    >
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import { BASE_URL } from "./constants.js";
import { STYLE } from "./constants.js";
import * as basics from "./basics.js";
import genericReload from "./genericReload.js";
export default {
  name: "Apuntate",
  data: () => ({
    value: true,
    formHtml: "",
    message: "",
    plazas: null,
    invita: 0,
    invitados: null,
    autorizado: true,
    menuDate: null,
    dateFormatted: null,
    menuDate1: null,
    dateFormatted1: null,
    menuDate2: null,
    dateFormatted2: null,
    menuDate3: null,
    dateFormatted3: null,
    menuDateBebe: null,
    dateFormattedBebe: null,
    idCentro: null,
    centros: [
      { id: 4, nombre: "Granada" },
      { id: 8, nombre: "Sevilla, Bellavista" },
      { id: 6, nombre: "Tomares (Sevilla)" },
    ],
    allWorkshops: [],
    workshops: [],
    selectWorkshops: null,
    rules: {
      nombre: [(val) => (val || "").length > 0 || "El nombre es obligatorio"],
      apellidos: [
        (val) => (val || "").length > 0 || "Los apellidos son obligatorios",
      ],
      email: [(val) => (val || "").length > 0 || "El email es obligatorio"],
    },
    post: {
      nombre: "",
      apellidos: "",
      fnac: "",
      fnacBebe: "",
      lopd: 1,
      email: "",
      telefono: "",
      regalo: "",
    },
    invita1: {
      nombre: "",
      apellidos: "",
      fnac: "",
      fnacBebe: "",
      lopd: 1,
      email: "",
      telefono: "",
      regalo: "",
    },
    invita2: {
      nombre: "",
      apellidos: "",
      fnac: "",
      fnacBebe: "",
      lopd: 1,
      email: "",
      telefono: "",
      regalo: "",
    },
    invita3: {
      nombre: "",
      apellidos: "",
      fnac: "",
      fnacBebe: "",
      lopd: 1,
      email: "",
      telefono: "",
      regalo: "",
    },
  }),
  async mounted() {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    const formattedDate = `${yyyy}-${mm}-${dd}`;
    const response = await genericReload(
      "",
      "api/withoutTokenWorkshop?desactivado=1&fechaInicio=" + formattedDate
    );
    if (response.status === 200) {
      let json = await response.json();
      json.forEach((workshop) => {
        const [fecha, hora] = workshop.fecha.split(" ");
        const formattedDate = `${fecha}T${hora}`;
        workshop.dateToOrder = new Date(formattedDate);
        workshop.fecha = basics.formatDate(fecha, "dd/mm/yyyy") + " " + hora;
      });

      json = json.sort((a, b) => a.dateToOrder - b.dateToOrder);

      this.allWorkshops = json.reverse();
      this.allWorkshops = json.filter((workshop) => workshop.desactivado == 0);
    }
  },
  methods: {
    clear() {
      this.message = "";
      this.invita = 0;
      this.invita1 = {
        nombre: "",
        apellidos: "",
        fnac: "",
        fnacBebe: "",
        lopd: 1,
        email: "",
        telefono: "",
        regalo: "",
      };
      this.invita2 = {
        nombre: "",
        apellidos: "",
        fnac: "",
        fnacBebe: "",
        lopd: 1,
        email: "",
        telefono: "",
        regalo: "",
      };
      this.invita3 = {
        nombre: "",
        apellidos: "",
        fnac: "",
        fnacBebe: "",
        lopd: 1,
        email: "",
        telefono: "",
        regalo: "",
      };
      const selectedWorkshop = this.workshops.find(
        (workshop) => workshop.id === this.selectWorkshops
      );
      this.plazas = selectedWorkshop.plazas - selectedWorkshop.registrados;
    },
    loadWorkshopFilter() {
      this.workshops = this.allWorkshops;
      this.workshops = this.workshops.filter(
        (workshop) => workshop.idCentro === this.idCentro
      );
    },
    eurosToCents(euros) {
      const cents = Math.round(parseFloat(euros) * 100);
      return cents;
    },
    async buy() {
      if (basics.validEmail(this.post.email)) {
        if (
          this.invita1.nombre != "" &&
          this.invita1.telefono == "" &&
          this.invita1.email == ""
        ) {
          this.message =
            "Por favor, debe añadir un email o teléfono válido para el primer invitado";
          return;
        }
        if (
          this.invita2.nombre != "" &&
          this.invita2.telefono == "" &&
          this.invita2.email == ""
        ) {
          this.message =
            "Por favor, debe añadir un email o teléfono válido para el segundo invitado";
          return;
        }
        if (
          this.invita3.nombre != "" &&
          this.invita3.telefono == "" &&
          this.invita3.email == ""
        ) {
          this.message =
            "Por favor, debe añadir un email o teléfono válido para el tercer invitado";
          return;
        }
        const selectedWorkshop = this.workshops.find(
          (workshop) => workshop.id === this.selectWorkshops
        );
        this.message = "";
        const params = new URLSearchParams({
          amount: JSON.stringify(this.eurosToCents(selectedWorkshop.precio)),
          workshop: JSON.stringify(selectedWorkshop),
        }).toString();

        localStorage.setItem("workshop", JSON.stringify(selectedWorkshop));
        localStorage.setItem("post", JSON.stringify(this.post));
        localStorage.setItem("invita1", JSON.stringify(this.invita1));
        localStorage.setItem("invita2", JSON.stringify(this.invita2));
        localStorage.setItem("invita3", JSON.stringify(this.invita3));

        const response = await fetch(`${BASE_URL}/api/pay/?${params}`); // URL del backend
        this.formHtml = await response.text();
        setTimeout(function () {
          document.getElementById("redsys-form").style.display = "block";
        }, 1000);

        let emailContent =
          this.post.nombre.toUpperCase().bold() +
          " " +
          this.post.apellidos.toUpperCase().bold() +
          " ha iniciado el proceso de compra para el Taller " +
          selectedWorkshop.nombre + " del " + selectedWorkshop.fecha;
          if(this.post.email!=""){
            emailContent += ". Su email es " +
            this.post.email.bold();
          }
          if(this.post.telefono!=""){
            emailContent += ". Su teléfono es " +
            this.post.telefono.bold();
          }
        if(this.invita1 && this.invita1.nombre != ""){
          emailContent += ". Trae como invitado a "+this.invita1.nombre+" "+this.invita1.apellidos;
          if(this.invita1.email){
            emailContent += ". Email: "+this.invita1.email;
          }
          if(this.invita1.telefono){
            emailContent += ". Teléfono: "+this.invita1.telefono;
          }
        }
        if(this.invita2 && this.invita2.nombre != ""){
          emailContent += ". También trae como invitado a "+this.invita2.nombre+" "+this.invita2.apellidos;
          if(this.invita2.email){
            emailContent += ". Email: "+this.invita2.email;
          }
          if(this.invita2.telefono){
            emailContent += ". Teléfono: "+this.invita2.telefono;
          }
        }
        if(this.invita3 && this.invita3.nombre != ""){
          emailContent += ". Y también a "+this.invita3.nombre+" "+this.invita3.apellidos;
          if(this.invita3.email){
            emailContent += ". Email: "+this.invita3.email;
          }
          if(this.invita3.telefono){
            emailContent += ". Teléfono: "+this.invita3.telefono;
          }
        }
        const emailSubject = "Inicio de proceso de COMPRA DE TALLER";
        const headersEmail = {
          "Content-Type": "application/json",
        };
        const emailData = {
          email: "comunicacion@lahabitacionsaludable.com",
          content: emailContent,
          subject: emailSubject,
        };
        await fetch(`${BASE_URL}/api/mailing`, {
          method: "POST",
          headers: headersEmail,
          body: JSON.stringify(emailData),
        });
      } else {
        this.message = "Por favor, inserte un email válido";
      }
    },
    changeDateFormatted(field) {
      switch (field) {
        case "date":
          if (this.post.fnac) {
            this.dateFormatted = basics.formatDate(
              this.post.fnac,
              "dd/mm/yyyy"
            );
          }
          break;
        case "date1":
          if (this.invita1.fnac) {
            this.dateFormatted1 = basics.formatDate(
              this.invita1.fnac,
              "dd/mm/yyyy"
            );
          }
          break;
        case "date2":
          if (this.invita2.fnac) {
            this.dateFormatted2 = basics.formatDate(
              this.invita2.fnac,
              "dd/mm/yyyy"
            );
          }
          break;
        case "date3":
          if (this.invita3.fnac) {
            this.dateFormatted3 = basics.formatDate(
              this.invita3.fnac,
              "dd/mm/yyyy"
            );
          }
          break;
        case "dateBebe":
          if (this.post.fnacBebe) {
            this.dateFormattedBebe = basics.formatDate(
              this.post.fnacBebe,
              "dd/mm/yyyy"
            );
          }
          break;
      }
    },
    invitar() {
      const selectedWorkshop = this.workshops.find(
        (workshop) => workshop.id === this.selectWorkshops
      );
      if(this.invitados=="no"){
        this.message = "";
      }else{
        if (selectedWorkshop && selectedWorkshop.nombre) {
          if (this.plazas - this.invita - 1 > 0) {
            if (selectedWorkshop.nombre.includes("RCP")) {
              if (this.invita == 0 && (this.invitados == "yes" || this.invitados == null)) {
                this.invita++;
                if(this.invitados == null) { this.invitados = "yes" }
              } else {
                this.message =
                  "No puedes invitar a más de una persona a este taller";
              }
            } else {
              if (selectedWorkshop.nombre.includes("BLW")) {
                if (this.invita < 2 && (this.invitados == "yes" || this.invitados == null)) {
                  this.invita++;
                  if(this.invitados == null) { this.invitados = "yes" }
                } else {
                  this.message =
                    "No puedes invitar a más de dos personas a este taller";
                }
              } else {
                if (this.invita == 0 && (this.invitados == "yes" || this.invitados == null)) {
                  this.invita++;
                  if(this.invitados == null) { this.invitados = "yes" }
                } else {
                  this.message =
                    "No puedes invitar a más de una persona a este taller";
                }
              }
            }
          } else {
            this.message = "No hay más plazas disponibles para este taller";
          }
        } else {
          this.message =
            "Debes seleccionar un taller antes de invitar a más personas";
        }
      }
    },
  },
};
</script>
<style scoped>
h1 {
  color: #fff;
  text-align: center;
  padding-bottom: 5%;
}
main {
  background-color: #31e9f5;
  background-size: cover;
}
</style>
